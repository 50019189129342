import * as React from 'react'
import { HiOutlineX } from 'react-icons/hi'

import Button from '@/components/core/Button'
import * as Icon from '@/components/core/internicons'
import SlideModal from '@/components/core/Modals/SlideModal'
import { env } from '@/utils/envs'
import { c } from '@/utils/etc'

import { useClicksignWidget } from './ClicksignWidget.hook'

const ClicksignWidget: React.FCC = () => {
  const {
    signatureKey,
    isDismissed,
    isSkipIntro,
    isLoading,
    assinado,
    origin,
    step,
    nextStep,
    dismiss,
    load,
  } = useClicksignWidget()

  return (
    <SlideModal
      onClose={dismiss}
      open={Boolean(!isDismissed && signatureKey)}
      customWidth="w-full"
      panelClassName="h-full px-0 pt-14 pb-0"
    >
      <Button onClick={dismiss} className="p-2 absolute top-2 right-2">
        <HiOutlineX className="w-6 h-6" />
      </Button>

      {assinado && (
        <span className="absolute inset-x-0 bottom-12 flex items-center justify-center z-10">
          <Button onClick={dismiss} className="button--primary">
            Fechar
          </Button>
        </span>
      )}

      {!isSkipIntro && (
        <SlideModal.Child
          enterClassName="pt-14"
          leave={!isDismissed}
          open={!isDismissed && step === 0}
        >
          <div className="w-full h-full space-y-6 max-w-lg mx-auto flex flex-col justify-center px-6">
            <Icon.Alert className="w-20 h-20 mx-auto" />

            <h1 className="text-title-sm sm:text-title-md font-bold text-center">
              Você tem um documento pendente de assinatura
            </h1>
            <p className="text-center sm:text-body-lg">
              Siga os passos para efetuar a assinatura e ter uma experiência
              completa em nossa plataforma
            </p>
            <Button onClick={nextStep} className="button--primary mx-auto">
              Iniciar assinatura
            </Button>
          </div>
        </SlideModal.Child>
      )}

      <SlideModal.Child
        enterClassName="pt-7"
        leave={!isDismissed}
        open={!isDismissed && step === (isSkipIntro ? 0 : 1)}
      >
        <div className="w-full h-full">
          {!isDismissed && isLoading && (
            <div className="-z-10 absolute inset-0 flex flex-col items-center justify-center px-6">
              <h1 className="text-2xl sm:text-3xl font-bold mb-4">
                Carregando documento...
              </h1>
              <p className="text-center">
                Aguarde o carregamento para completar a assinatura
              </p>
            </div>
          )}

          <iframe
            width="100%"
            height="100%"
            src={`${env.CLICKSIGN_URL}/sign/${signatureKey}?embedded=true&origin=${origin}`}
            allow="camera"
            className={c(
              'transition-opacity opacity-0 focus-visible:outline-none',
              !isLoading && 'opacity-100'
            )}
            onLoad={load}
          />
        </div>
      </SlideModal.Child>
    </SlideModal>
  )
}

export default ClicksignWidget
