import { Transition } from '@headlessui/react'
import * as React from 'react'

import { c } from '@/utils/etc'

import type { BaseModalProps } from '../BaseModal/BaseModal'
import BaseModal from '../BaseModal/BaseModal'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SlideModalProps extends BaseModalProps {}

const SlideModal: React.FCC<SlideModalProps> = ({
  children,
  panelClassName,
  ...baseProps
}) => {
  return (
    <BaseModal
      panelClassName={c(
        'overflow-x-hidden relative flex items-center',
        panelClassName
      )}
      {...baseProps}
    >
      {children}
    </BaseModal>
  )
}

type SlideModalChildProps = Pick<BaseModalProps, 'open'> & {
  leave?: boolean
  enterClassName?: string
}

const SlideModalChild: React.FCC<SlideModalChildProps> = ({
  open,
  children,
  leave = true,
  enterClassName,
}) => {
  return (
    <Transition
      show={open}
      unmount={false}
      as={React.Fragment}
      enter={c(
        'transition-all transform-gpu duration-500 absolute',
        enterClassName
      )}
      enterFrom="translate-x-[100%]"
      enterTo="translate-x-0"
      leave="transition-all transform-gpu duration-500"
      leaveFrom="translate-x-0"
      leaveTo={c(leave ? '-translate-x-[200%]' : '')}
    >
      {children}
    </Transition>
  )
}

// @ts-expect-error expect
SlideModal.Child = SlideModalChild

export default SlideModal as typeof SlideModal & {
  Child: typeof SlideModalChild
}
export { SlideModalChild as Child }
