import mixpanel from 'mixpanel-browser'

import { env } from '@/utils/envs'

mixpanel.init(env.MIXPANEL_TOKEN, {
  debug: process.env.NODE_ENV === 'development',
  ignore_dnt: true,
})

export { mixpanel }
