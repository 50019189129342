import { useQueryClient } from '@tanstack/react-query'
import * as React from 'react'
import { create as zustand } from 'zustand'

import type { DeepPartial } from '@/utils/types'
import type { MeResponseDto } from '@/utils/types/api/portal/dto'

declare global {
  const Clicksign: any
}

interface ClicksignStore {
  signatureKey?: string
  step: number
  loading: boolean
  skipIntro: boolean
  dismissed: boolean
  assinado: boolean

  dismiss: () => void
  load: () => void
  open: (key: string, opts?: { force?: boolean; skipIntro?: boolean }) => void
  assinar: () => void
  nextStep: () => void
}

export const useClicksignStore = zustand<ClicksignStore>((set, get) => ({
  dismissed: false,
  loading: true,
  skipIntro: false,
  assinado: false,
  step: 0,

  dismiss: () =>
    set({
      step: 0,
      dismissed: true,
      skipIntro: false,
      loading: true,
      assinado: false,
    }),

  load: () => set({ loading: false }),

  open: (signatureKey, opts) =>
    set({
      signatureKey,
      ...(opts?.force ? { dismissed: false } : {}),
      ...(opts?.skipIntro ? { skipIntro: true } : {}),
    }),

  assinar: () => set({ assinado: true }),

  nextStep: () => set({ step: get().step + 1 }),
}))

export const useClicksignWidget = () => {
  const isDismissed = useClicksignStore(({ dismissed }) => dismissed)
  const isLoading = useClicksignStore(({ loading }) => loading)
  const isSkipIntro = useClicksignStore(({ skipIntro }) => skipIntro)
  const signatureKey = useClicksignStore(({ signatureKey }) => signatureKey)
  const step = useClicksignStore(({ step }) => step)
  const assinado = useClicksignStore(({ assinado }) => assinado)

  const queryClient = useQueryClient()
  const autoCloseRef = React.useRef<NodeJS.Timeout>()

  const dismiss = useClicksignStore(({ dismiss }) => dismiss)
  const load = useClicksignStore(({ load }) => load)
  const nextStep = useClicksignStore(({ nextStep }) => nextStep)
  const assinar = useClicksignStore(({ assinar }) => assinar)

  const origin = React.useMemo(() => {
    if (typeof document === 'undefined') return
    return `${location.protocol}//${location.host}`
  }, [])

  React.useEffect(() => {
    const handle = ({ data }: MessageEvent) => {
      if (data !== 'signed') return

      assinar()

      queryClient.setQueryData<DeepPartial<MeResponseDto>>(['me'], (old) => ({
        ...old,
        integrador: old?.integrador && {
          ...old?.integrador,
          assinaturas: old?.integrador?.assinaturas?.map((a) => {
            if (a?.assinaturaId !== signatureKey) return a
            return { ...a, assinado: true }
          }),
        },
      }))
    }

    window.addEventListener('message', handle)

    return () => {
      window.removeEventListener('message', handle)
    }
  }, [assinar, queryClient, signatureKey])

  React.useEffect(() => {
    if (!assinado) return

    autoCloseRef.current = setTimeout(dismiss, 15000)

    return () => {
      if (autoCloseRef.current) clearTimeout(autoCloseRef.current)
    }
  }, [assinado, dismiss])

  return {
    signatureKey,
    isDismissed,
    isSkipIntro,
    isLoading,
    assinado,
    origin,
    step,
    nextStep,
    dismiss,
    load,
  }
}
