import React from 'react'

import { useAuth } from '@/hooks/use-auth'
import { mixpanel } from '@/libs/mixpanel'
import { nomeUsuario } from '@/utils/core/etc'

const MixpanelConfig: React.FC = () => {
  const { user } = useAuth()

  const credencial = user.credencial
  const perfil = user.perfil

  const mixpanelIdentityRef = React.useRef({ credencial, perfil })
  const mixpanelIdentity = React.useMemo(() => {
    const next = { credencial, perfil }
    if (JSON.stringify(mixpanelIdentityRef.current) === JSON.stringify(next))
      return mixpanelIdentityRef.current
    mixpanelIdentityRef.current = next
    return mixpanelIdentityRef.current
  }, [credencial, perfil])

  React.useEffect(() => {
    const { credencial, perfil } = mixpanelIdentity

    if (!credencial?.id || !perfil?.id) return

    mixpanel.identify(credencial.id)
    mixpanel.people.set({
      $email: credencial.email,
      $name: nomeUsuario(perfil),
      roles: credencial.roles,
    })
  }, [mixpanelIdentity])

  return null
}

export default MixpanelConfig
