import React from 'react'

import { useAuth } from '@/hooks/use-auth'
import type { Integrador } from '@/utils/types/structs/auth'

import ClicksignWidget from './ClicksignWidget'
import { useClicksignStore } from './ClicksignWidget/ClicksignWidget.hook'

const ClicksignIntegrador: React.FC = () => {
  const { user, context } = useAuth()
  const openClicksign = useClicksignStore(({ open }) => open)

  React.useEffect(() => {
    if (context !== 'INTEGRADOR') return
    const _p = user.perfil as Integrador
    const key =
      _p?.assinaturas?.find(
        ({ assinado, contrato }) => !assinado && !contrato?.inativo
      )?.assinaturaId || undefined

    if (!key) return

    openClicksign(key)
  }, [context, openClicksign, user])

  if (context !== 'INTEGRADOR') return null

  return <ClicksignWidget />
}

export default ClicksignIntegrador
